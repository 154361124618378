import { BACKEND_URL } from "../utils/urls";
import BaseService from './BaseService';

export default class ClinicsService extends BaseService {
    async getClinics(clinic, alias, typeOfScreening, typeOfEhr) {
        const url = `/api/carePartners`;
        
        // Create axios config with params
        const config = {
            params: {
                clinic: clinic || null,
                typeOfScreening: typeOfScreening || null,
                typeOfEhr: typeOfEhr || null,
                alias: alias || null
            }
        };
        
        try {
            return await this.get(url, config);
        } catch (error) {
            console.error("Error in getClinics:", error);
            return [];
        }
    }

    async getClinicDetails(id) {
        const url = `/api/carePartners/${id}`;
        return await this.get(url);
    }

    async deleteClinic(id) {
        const url = `/api/carePartners/${id}`;
        return await this.delete(url);
    }

    async updateClinic(id, clinicData) {
        const url = `/api/carePartners/${id}`;
        
        // Create config with proper headers
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        
        return await this.put(url, clinicData, config);
    }

    async insertClinic(data) {
        const url = `/api/carePartners`;
        
        // Create config with proper headers
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        
        return await this.post(url, data, config);
    }
}

// Create a singleton instance to export
const clinicsService = new ClinicsService();

// Export the methods from the instance
export const getClinics = clinicsService.getClinics.bind(clinicsService);
export const getClinicDetails = clinicsService.getClinicDetails.bind(clinicsService);
export const deleteClinic = clinicsService.deleteClinic.bind(clinicsService);
export const updateClinic = clinicsService.updateClinic.bind(clinicsService);
export const insertClinic = clinicsService.insertClinic.bind(clinicsService);